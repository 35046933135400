import React from 'react';
import _ from 'lodash';
import {graphql} from 'gatsby';

import components, {Layout} from '../components/index';
import * as EmailJS from 'emailjs-com';

// this minimal GraphQL query ensures that when 'gatsby develop' is running,
// any changes to content files are reflected in browser
export const query = graphql`
  query($url: String) {
    sitePage(path: {eq: $url}) {
      id
    }
  }
`;

export default class Advanced extends React.Component {
  constructor(props){
    super(props);
    this.wrapperRef = React.createRef();
    this.submitForm = this.submitForm.bind(this);
  }
  submitForm() {
    // get the values from the form
    let wrapper = this.wrapperRef.current;
    let consent = wrapper.querySelectorAll("#consent")[0];
    if (consent.value === 'on') {
      let name = wrapper.querySelectorAll("#name")[0];
      let email = wrapper.querySelectorAll("#email")[0];
      let subject = wrapper.querySelectorAll("#subject")[0];
      let message = wrapper.querySelectorAll("#message")[0];
      let sendingData = {
        sender_name: name.value,
        sender_email: email.value,
        message_subject: subject.value,
        message_content: message.value
      }
      // send the email
      EmailJS.send( 'service_crwr0o8', 'template_b7m3lr9', sendingData, 'user_d1xS3GnTgnwoKheBfIIE9')
        .then((response) => {
          console.debug(response.status, response.text);
          // clear the values
          name.value = email.value = subject.value = message.value = '';
          consent.checked = false;
          // display the message
          wrapper.querySelectorAll('#message-node')[0].style.display = 'block';
        })
        .catch((error) => console.debug(error));
    }
  }
  componentDidMount() {
    if (window.location.href.includes('contact')) {
      // add the message element
      this.wrapperRef.current
        .querySelectorAll('#contactForm > div.form-submit')[0]
        .innerHTML += '<p id="message-node" style="display: none; margin-top: 1em;">Your message has been sent. I will be in touch.</p>';
      // update the submit button's type
      this.wrapperRef.current
        .querySelectorAll('#contactForm > div.form-submit > button')[0]
        .type = 'button';
      // add the event listener
      this.wrapperRef.current
        .querySelectorAll("#contactForm > div.form-submit > button")[0]
        .addEventListener('click', this.submitForm);
    }
  }
  render() {
    return (
      <Layout {...this.props}>
        {(!_.get(this.props, 'pageContext.frontmatter.hide_title', null)) && (
          <header className="post-header inner-sm">
            <h1 className="post-title underline">{_.get(this.props, 'pageContext.frontmatter.title', null)}</h1>
          </header>
        )}
        {_.map(_.get(this.props, 'pageContext.frontmatter.sections', null), (section, section_idx) => {
          let component = _.upperFirst(_.camelCase(_.get(section, 'type', null)));
          let Component = components[component];
          return (
            <div ref={this.wrapperRef}>
              <Component
                key={section_idx}
                {...this.props}
                section={section}
                site={this.props.pageContext.site} 
              />
            </div>
          )
        })}
      </Layout>
    );
    }
}
